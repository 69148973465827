import React, { useState } from 'react';
import { graphql, Link } from 'gatsby';
import { DashboardLayout } from '../modules/layout/components/dashboard-layout';
import { IReverseCharacter } from '../modules/common/model/graphql-types';
import { getImage, GatsbyImage, StaticImage } from 'gatsby-plugin-image';

import './employee.scss';
import './rev-character-dynamic.scss';
import {
  Button,
  Card,
  Col,
  OverlayTrigger,
  Popover,
  Row
} from 'react-bootstrap';
import { Seo } from '../modules/common/components/seo';
import { Options } from '@contentful/rich-text-react-renderer';
import { INLINES, BLOCKS } from '@contentful/rich-text-types';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBook,
  faCircleInfo,
  faDiagramProject,
  faPeopleGroup,
  faReceipt,
  faSquare,
  faWaveSquare
} from '@fortawesome/free-solid-svg-icons';
import { RatingBox } from '../modules/common/components/rating-box';
import lodash from 'lodash';
import { RevTagsArray } from '../modules/reverse/common/components/rev-tags';
import { ReverseItem } from '../modules/reverse/common/components/rev-item';
import { Reverse1999Psychube } from '../modules/reverse/common/components/rev-psychube';
import { YoutubeEmbed } from '../modules/common/components/youtube-embed';
import { OutboundLink } from 'gatsby-plugin-google-gtag';

const Text = ({ children }) => <p>{children}</p>;

const options: Options = {
  renderNode: {
    [INLINES.HYPERLINK]: (node, children) => {
      if (node.data.uri.startsWith('/')) {
        return <Link to={node.data.uri}>{children}</Link>;
      } else {
        return (
          <a href={node.data.uri} target="_blank" rel="noreferrer">
            {children}
          </a>
        );
      }
    },
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    ['BLOCKS.EMBEDDED_ASSET']: (node) => {
      return (
        <>
          <pre>
            <code>{JSON.stringify(node, null, 2)}</code>
          </pre>
        </>
      );
    },
    'embedded-asset-block': (node) => {
      const { localFile } = node.data.target;
      if (!localFile || !localFile.childImageSharp) {
        return null;
      }
      const image = getImage(localFile.childImageSharp);
      return <GatsbyImage image={image} alt="" />;
    }
  }
};

const SpecialityItem = ({ speciality }) => {
  const specialityObj = lodash.find(RevTagsArray, {
    value: speciality
  });
  if (specialityObj) {
    const popoverSpecialities = (
      <Popover id="popover-specialities">
        <Popover.Header as="h3">{specialityObj.label}</Popover.Header>
        <Popover.Body>{specialityObj.description}</Popover.Body>
      </Popover>
    );
    return (
      <OverlayTrigger
        trigger="click"
        rootClose
        placement="auto-start"
        overlay={popoverSpecialities}
      >
        <div className="employee-role">{specialityObj.label}</div>
      </OverlayTrigger>
    );
  } else {
    return <span>{speciality}</span>;
  }
};

interface IReverseCharacterNodes {
  nodes: IReverseCharacter[];
}

interface IReverseCharacterEntry {
  currentUnit: IReverseCharacterNodes;
}

interface IProps {
  data: IReverseCharacterEntry;
}

const ReverseCharacterDetails: React.FC<IProps> = ({ data }) => {
  const character = data.currentUnit.nodes[0];
  const [currentTab, setCurrentTab] = useState('Info');

  const fullImage = character.imageFull
    ? getImage(character.imageFull.localFile.childImageSharp)
    : null;

  const fullInsight = character.imageInsight
    ? getImage(character.imageInsight.localFile.childImageSharp)
    : null;

  const profileImage = character.imageCard
    ? getImage(character.imageCard.localFile.childImageSharp)
    : null;

  return (
    <DashboardLayout
      className={'generic-page character-page character-reverse-v2 '}
      game="reverse"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/re1999/">Reverse: 1999</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/re1999/characters">Characters</Link>
        </li>
        <li className="divider">/</li>
        <li>{character.name}</li>
      </ul>
      <>
        <div className="character-top">
          <div className="left-info">
            <h1>
              <span className="small"></span>
              <strong className={`${character.afflatus}`}>
                {character.name}
              </strong>
              <span className="sub">Build and Guide</span>
            </h1>
          </div>
          <div className="right-image">
            <div
              className={`character-background ${character.afflatus} ${character.slug}`}
            ></div>
            <GatsbyImage image={profileImage} alt="Character" />
          </div>
        </div>
        <Row className="intro-section">
          <Col xs={12} xl={9}>
            <div className={`content-header ${character.afflatus}`}>
              <FontAwesomeIcon icon={faSquare} width="18" /> Introduction
            </div>
            <div className="character-intro">
              <div className="combined">
                <h2>
                  <strong className={`${character.afflatus}`}>
                    {character.name}
                  </strong>{' '}
                  is a{' '}
                  <strong className={`rarity-${character.rarity}`}>
                    {character.rarity}✦
                  </strong>{' '}
                  rarity character from the{' '}
                  <strong className={`${character.afflatus}`}>
                    {character.afflatus}
                  </strong>{' '}
                  afflatus who deals{' '}
                  <strong>
                    {character.damageType === 'Damage'
                      ? 'DPS'
                      : character.damageType}
                  </strong>{' '}
                  damage and belongs to the{' '}
                  <strong>{character.tierListCategory}</strong> class.
                </h2>
                {character.tierComment ? (
                  <p>{character.tierComment.tierComment}</p>
                ) : (
                  ''
                )}
              </div>
              <p className="hide-on-mobile">
                To learn more about{' '}
                <strong className={`${character.afflatus}`}>
                  {character.name}
                </strong>{' '}
                check the sections below. <strong>Use the tabs</strong> to
                quickly switch to the kind of information you're looking for.
              </p>
            </div>
          </Col>
          <Col xs={12} xl={3}>
            <div className="video-promo">
              <div className={`content-header ${character.afflatus}`}>
                <FontAwesomeIcon icon={faSquare} width="18" /> Video guide
              </div>
              <div className="video-guide">
                {character.slug != 'melania' &&
                  character.slug != 'jessica' &&
                  character.slug != 'tooth-fairy' &&
                  character.slug != 'diggers' &&
                  character.slug != 'horropedia' &&
                  character.slug != 'isolde' &&
                  character.slug != 'getian' &&
                  character.slug != 'yenisei' &&
                  character.slug != 'kanjira' &&
                  character.slug != 'blonney' &&
                  character.slug != 'pickles' && (
                    <p>This character has no video guide yet. </p>
                  )}
                {character.slug === 'melania' && (
                  <>
                    <Row className="video-row">
                      <Col xs={12} lg={12}>
                        <YoutubeEmbed embedId="tJlnthLxung" />
                      </Col>
                    </Row>
                  </>
                )}
                {character.slug === 'jessica' && (
                  <>
                    <Row className="video-row">
                      <Col xs={12} lg={12}>
                        <YoutubeEmbed embedId="KnG4-XxQ7jw" />
                      </Col>
                    </Row>
                  </>
                )}
                {character.slug === 'pickles' && (
                  <>
                    <Row className="video-row">
                      <Col xs={12} lg={12}>
                        <YoutubeEmbed embedId="Sx5w7-TSBRI" />
                      </Col>
                    </Row>
                  </>
                )}
                {character.slug === 'yenisei' && (
                  <>
                    <Row className="video-row">
                      <Col xs={12} lg={12}>
                        <YoutubeEmbed embedId="QhYHfvWyZeo" />
                      </Col>
                    </Row>
                  </>
                )}
                {character.slug === 'tooth-fairy' && (
                  <>
                    <Row className="video-row">
                      <Col xs={12} lg={12}>
                        <YoutubeEmbed embedId="eMb4fx-cfkQ" />
                      </Col>
                    </Row>
                  </>
                )}
                {character.slug === 'getian' && (
                  <>
                    <Row className="video-row">
                      <Col xs={12} lg={12}>
                        <YoutubeEmbed embedId="CB-rfb5VgPY" />
                      </Col>
                    </Row>
                  </>
                )}
                {character.slug === 'blonney' && (
                  <>
                    <Row className="video-row">
                      <Col xs={12} lg={12}>
                        <YoutubeEmbed embedId="UiTH3biF7-k" />
                      </Col>
                    </Row>
                  </>
                )}
                {character.slug === 'diggers' && (
                  <>
                    <Row className="video-row">
                      <Col xs={12} lg={12}>
                        <YoutubeEmbed embedId="iYzevM5bEB8" />
                      </Col>
                    </Row>
                  </>
                )}
                {character.slug === 'horropedia' && (
                  <>
                    <Row className="video-row">
                      <Col xs={12} lg={12}>
                        <YoutubeEmbed embedId="bO63cUjDuAg" />
                      </Col>
                    </Row>
                  </>
                )}
                {character.slug === 'kanjira' && (
                  <>
                    <Row className="video-row">
                      <Col xs={12} lg={12}>
                        <YoutubeEmbed embedId="ww-8l4KROoM" />
                      </Col>
                    </Row>
                  </>
                )}
                {character.slug === 'isolde' && (
                  <>
                    <Row className="video-row">
                      <Col xs={12} lg={12}>
                        <YoutubeEmbed embedId="3YQrWNBH3do" />
                      </Col>
                    </Row>
                  </>
                )}
              </div>
            </div>
          </Col>
        </Row>
        <div className="last-updated">
          <div className={`content-header ${character.afflatus}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Last updated
          </div>
          <div className="info-box with-margin">
            <p>
              <strong className={`${character.afflatus}`}>
                {character.name}
              </strong>{' '}
              profile was last updated on <strong>{character.updatedAt}</strong>
              .
            </p>
          </div>
        </div>
        <div
          className="fuse-ad-placeholder bigger"
          data-fuse="22844297232"
        ></div>
        <p className="show-on-mobile">
          To learn more about{' '}
          <strong className={`${character.afflatus}`}>{character.name}</strong>{' '}
          check the sections below. <strong>Use the tabs</strong> to quickly
          switch to the kind of information you're looking for.
        </p>
        <div className="tabs">
          <div
            className={`single-tab ${character.afflatus} ${
              currentTab === 'Info' ? 'active' : ''
            }`}
            onClick={() => setCurrentTab('Info')}
          >
            <div className="top-icon">
              <FontAwesomeIcon icon={faCircleInfo} width="18" />
            </div>
            <p>Profile</p>
          </div>
          <div
            className={`single-tab ${character.afflatus} ${
              currentTab === 'Review' ? 'active' : ''
            }`}
            onClick={() => setCurrentTab('Review')}
          >
            <div className="top-icon">
              <FontAwesomeIcon icon={faBook} width="18" />
            </div>
            <p>Review</p>
          </div>
          <div
            className={`single-tab ${character.afflatus} ${
              currentTab === 'Build' ? 'active' : ''
            }`}
            onClick={() => setCurrentTab('Build')}
          >
            <div className="top-icon">
              <FontAwesomeIcon icon={faDiagramProject} width="18" />
            </div>
            <p>Stats & Build</p>
          </div>
          <div
            className={`single-tab ${character.afflatus} ${
              currentTab === 'Teams' ? 'active' : ''
            }`}
            onClick={() => setCurrentTab('Teams')}
          >
            <div className="top-icon">
              <FontAwesomeIcon icon={faPeopleGroup} width="18" />
            </div>
            <p>Synergy & Teams</p>
          </div>
          <div
            className={`single-tab ${character.afflatus} ${
              currentTab === 'Performance' ? 'active' : ''
            }`}
            onClick={() => setCurrentTab('Performance')}
          >
            <div className="top-icon">
              <FontAwesomeIcon icon={faReceipt} width="18" />
            </div>
            <p>Lore</p>
          </div>
        </div>
        <div className={`tab-inside ${currentTab === 'Info' ? 'active' : ''}`}>
          <div className={`mobile-header ${character.afflatus}`}>
            <p>
              <FontAwesomeIcon icon={faCircleInfo} width="18" />
              Profile
            </p>
          </div>
          <div className={`content-header ${character.afflatus}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Specialties
          </div>
          <div className="specialities-list">
            {character.tags ? (
              character.tags.map((speciality, idx) => {
                return <SpecialityItem key={idx} speciality={speciality} />;
              })
            ) : (
              <span className="no-spec">-</span>
            )}
          </div>
          <div className={`content-header ${character.afflatus}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Skills
          </div>
          {character.availableInGlobal === false && (
            <>
              <div className="info-box with-margin">
                <p>
                  <strong className={`${character.afflatus}`}>
                    {character.name}
                  </strong>{' '}
                  was released during the{' '}
                  <strong>{character.releasePatch} patch</strong> in the CN
                  version of the game. We translated{' '}
                  <strong className={`${character.afflatus}`}>
                    {character.name}
                  </strong>{' '}
                  skills ourselves, as such, there's a chance of localization
                  errors. We will add the official translations as soon as they
                  will be released. Please contact us directly to report any
                  errors.
                </p>
              </div>
            </>
          )}
          {character.skills ? (
            <>
              <Row xs={1} xxl={1} className="skills">
                {character.skills.map((skill, index) => {
                  const image = skill.image
                    ? getImage(skill.image.localFile.childImageSharp)
                    : null;
                  return (
                    <Col key={index}>
                      <div className="skill-box-rev">
                        <div className={`skill-header ${character.afflatus}`}>
                          {skill.name}
                        </div>
                        <div className="skill-row">
                          <div className="image-column">
                            <GatsbyImage
                              image={image}
                              alt="Skill"
                              className="skill-image"
                            />
                          </div>
                          <div className="info-column">
                            <div className="skill-content">
                              {skill.category === 'Ultimate' ? (
                                <>
                                  <div
                                    className={`skill-description rev-special`}
                                  >
                                    {renderRichText(
                                      skill.description1,
                                      options
                                    )}
                                  </div>
                                  <div className="flavor-text">
                                    "Ф{' '}
                                    {skill.flavorText1
                                      ? skill.flavorText1.flavorText1
                                      : '-'}
                                    "
                                  </div>
                                </>
                              ) : (
                                <Row xs={1} xxl={1}>
                                  <Col>
                                    <div className="info">
                                      <p>
                                        <span>✦</span>✧✧
                                      </p>
                                    </div>
                                    <div
                                      className={`skill-description rev-special`}
                                    >
                                      <p
                                        className={`skill-type ${skill.type1}`}
                                      >
                                        [{skill.type1}]
                                      </p>
                                      {renderRichText(
                                        skill.description1,
                                        options
                                      )}
                                    </div>
                                    <div className="flavor-text">
                                      "Ф{' '}
                                      {skill.flavorText1
                                        ? skill.flavorText1.flavorText1
                                        : '-'}
                                      "
                                    </div>
                                  </Col>
                                  <Col>
                                    <div className="info">
                                      <p>
                                        <span>✦✦</span>✧
                                      </p>
                                    </div>
                                    <div
                                      className={`skill-description rev-special`}
                                    >
                                      <p
                                        className={`skill-type ${skill.type2}`}
                                      >
                                        [{skill.type2}]
                                      </p>
                                      {renderRichText(
                                        skill.description2,
                                        options
                                      )}
                                    </div>
                                    <div className="flavor-text">
                                      "Ф{' '}
                                      {skill.flavorText2
                                        ? skill.flavorText2.flavorText2
                                        : '-'}
                                      "
                                    </div>
                                  </Col>
                                  <Col>
                                    <div className="info">
                                      <p>
                                        <span>✦✦✦</span>
                                      </p>
                                    </div>
                                    <div
                                      className={`skill-description rev-special`}
                                    >
                                      <p
                                        className={`skill-type ${skill.type3}`}
                                      >
                                        [{skill.type3}]
                                      </p>
                                      {renderRichText(
                                        skill.description3,
                                        options
                                      )}
                                    </div>
                                    <div className="flavor-text">
                                      "Ф{' '}
                                      {skill.flavorText3
                                        ? skill.flavorText3.flavorText3
                                        : '-'}
                                      "
                                    </div>
                                  </Col>
                                </Row>
                              )}
                            </div>
                            {skill.status ? (
                              <div className="status">
                                {skill.status.map((status, index) => {
                                  return (
                                    <div key={index}>
                                      <div
                                        className={`skill-description rev-special`}
                                      >
                                        <p className="name">
                                          <strong>{status.name}</strong>
                                          :&nbsp;
                                        </p>
                                        {renderRichText(
                                          status.description,
                                          options
                                        )}
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </>
          ) : (
            <div className="info-box">
              <p>
                <strong className={`${character.afflatus}`}>
                  {character.name}
                </strong>{' '}
                skills aren't available yet. They will be added soon!
              </p>
            </div>
          )}
          <div className={`content-header ${character.afflatus}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Inheritance
          </div>
          {character.inheritance ? (
            <>
              <Row xs={1} xxl={3} className="skills">
                <Col>
                  <div className="skill-box rev inheritance">
                    <div className="skill-header">
                      <div className={`skill-icon ${character.afflatus}`}>
                        <StaticImage
                          src="../images/reverse/icons/insight_1.png"
                          alt="Insight 1"
                        />
                      </div>
                      <div className="name-section">
                        <h5 className="name">{character.inheritance.name}</h5>
                        <span className="skill-type pill">Insight I</span>
                      </div>
                    </div>
                    <div className="skill-content">
                      <div className={`skill-description rev-special`}>
                        {renderRichText(character.inheritance.level1, options)}
                      </div>
                    </div>
                    {character.materials && (
                      <>
                        <div className="cost">
                          <h6>Materials</h6>
                          <ul>
                            {character.materials.insight_1.map(
                              (insight, index) => {
                                return (
                                  <li key={index}>
                                    <ReverseItem
                                      name={insight.name}
                                      value={insight.amount}
                                    />
                                  </li>
                                );
                              }
                            )}
                          </ul>
                        </div>
                      </>
                    )}
                  </div>
                </Col>
                <Col>
                  <div className="skill-box rev inheritance">
                    <div className="skill-header">
                      <div className={`skill-icon ${character.afflatus}`}>
                        <StaticImage
                          src="../images/reverse/icons/insight_2.png"
                          alt="Insight 1"
                        />
                      </div>
                      <div className="name-section">
                        <h5 className="name">{character.inheritance.name}</h5>
                        <span className="skill-type pill">Insight II</span>
                      </div>
                    </div>
                    <div className="skill-content">
                      <div className={`skill-description rev-special`}>
                        {renderRichText(character.inheritance.level2, options)}
                      </div>
                    </div>
                    {character.materials && (
                      <>
                        <div className="cost">
                          <h6>Materials</h6>
                          <ul>
                            {character.materials.insight_2.map(
                              (insight, index) => {
                                return (
                                  <li key={index}>
                                    <ReverseItem
                                      name={insight.name}
                                      value={insight.amount}
                                    />
                                  </li>
                                );
                              }
                            )}
                          </ul>
                        </div>
                      </>
                    )}
                  </div>
                </Col>
                {character.inheritance.level3 ? (
                  <Col>
                    <div className="skill-box rev inheritance">
                      <div className="skill-header">
                        <div className={`skill-icon ${character.afflatus}`}>
                          <StaticImage
                            src="../images/reverse/icons/insight_3.png"
                            alt="Insight 1"
                          />
                        </div>
                        <div className="name-section">
                          <h5 className="name">{character.inheritance.name}</h5>
                          <span className="skill-type pill">Insight III</span>
                        </div>
                      </div>
                      <div className="skill-content">
                        <div className={`skill-description rev-special`}>
                          {renderRichText(
                            character.inheritance.level3,
                            options
                          )}
                        </div>
                      </div>
                      {character.materials && (
                        <>
                          <div className="cost">
                            <h6>Materials</h6>
                            <ul>
                              {character.materials.insight_3.map(
                                (insight, index) => {
                                  return (
                                    <li key={index}>
                                      <ReverseItem
                                        name={insight.name}
                                        value={insight.amount}
                                      />
                                    </li>
                                  );
                                }
                              )}
                            </ul>
                          </div>
                        </>
                      )}
                    </div>
                  </Col>
                ) : (
                  ''
                )}
              </Row>
              {character.inheritance.status ? (
                <Row xs={1} xxl={1} className="skills">
                  <Col>
                    <div className="skill-box rev status">
                      <h6>Special effects</h6>
                      {character.inheritance.status.map((status, index) => {
                        return (
                          <div key={index}>
                            <p className="name">
                              <strong>{status.name}</strong>:&nbsp;
                            </p>
                            <div className={`skill-description rev-special`}>
                              {renderRichText(status.description, options)}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </Col>
                </Row>
              ) : (
                <></>
              )}
            </>
          ) : (
            <div className="info-box">
              <p>
                <strong className={`${character.afflatus}`}>
                  {character.name}
                </strong>{' '}
                Inheritance aren't available yet. They will be added soon!
              </p>
            </div>
          )}
          <div className={`content-header ${character.afflatus}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Portray
          </div>
          {character.portray ? (
            <Row xs={1} xxl={1} className="skills">
              <Col>
                <div className="skill-box rev portray">
                  <div className="skill-header">
                    <div className={`skill-icon ${character.afflatus}`}>
                      <FontAwesomeIcon width="36" icon={faWaveSquare} />
                    </div>
                    <div className="name-section">
                      <h5 className="name">{character.name} Portray</h5>
                      <span className="skill-type pill">Portray details</span>
                    </div>
                  </div>
                  <div className="skill-content">
                    <div>
                      <p className="name">
                        <strong>Lv. 1</strong>:&nbsp;
                      </p>
                      <div className={`skill-description rev-special`}>
                        {renderRichText(character.portray.level1, options)}
                      </div>
                    </div>
                    <div>
                      <p className="name">
                        <strong>Lv. 2</strong>:&nbsp;
                      </p>
                      <div className={`skill-description rev-special`}>
                        {renderRichText(character.portray.level2, options)}
                      </div>
                    </div>
                    <div>
                      <p className="name">
                        <strong>Lv. 3</strong>:&nbsp;
                      </p>
                      <div className={`skill-description rev-special`}>
                        {renderRichText(character.portray.level3, options)}
                      </div>
                    </div>
                    <div>
                      <p className="name">
                        <strong>Lv. 4</strong>:&nbsp;
                      </p>
                      <div className={`skill-description rev-special`}>
                        {renderRichText(character.portray.level4, options)}
                      </div>
                    </div>
                    <div>
                      <p className="name">
                        <strong>Lv. 5</strong>:&nbsp;
                      </p>
                      <div className={`skill-description rev-special`}>
                        {renderRichText(character.portray.level5, options)}
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          ) : (
            <div className="info-box">
              <p>
                <strong className={`${character.afflatus}`}>
                  {character.name}
                </strong>{' '}
                Inheritance aren't available yet. They will be added soon!
              </p>
            </div>
          )}
          <div className={`content-header ${character.afflatus}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Gallery
          </div>
          <Row xs={1} xl={2} className={`char-gallery`}>
            <Col>
              <div className="box">
                <h5 className={` ${character.afflatus}`}>Insight 0</h5>
                {fullImage ? (
                  <GatsbyImage
                    image={fullImage}
                    alt=""
                    className="full-image"
                  />
                ) : (
                  <p>Insight 0 image for this character isn't available yet.</p>
                )}
              </div>
            </Col>
            <Col>
              <div className="box">
                <h5 className={`${character.afflatus}`}>Insight 2</h5>
                {fullInsight ? (
                  <GatsbyImage
                    image={fullInsight}
                    alt=""
                    className="full-image"
                  />
                ) : (
                  <p>Insight 2 image for this character isn't available yet.</p>
                )}
              </div>
            </Col>
          </Row>
        </div>
        <div
          className={`tab-inside ${currentTab === 'Review' ? 'active' : ''}`}
        >
          <div className={`mobile-header ${character.afflatus}`}>
            <p>
              <FontAwesomeIcon icon={faBook} width="18" />
              Review
            </p>
          </div>
          <div className={`content-header ${character.afflatus}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Review
          </div>
          {character.review ? (
            <div className="section-analysis">
              <div className={`review raw`}>
                {character.review ? (
                  <>{renderRichText(character.review, options)}</>
                ) : (
                  <p>Review is pending.</p>
                )}
              </div>
            </div>
          ) : (
            <div className="info-box">
              <p>
                <strong className={`${character.afflatus}`}>
                  {character.name}
                </strong>{' '}
                <strong>review isn't available yet.</strong> It will be added
                soon!
              </p>
            </div>
          )}
          <div className={`content-header ${character.afflatus}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Pros & Cons
          </div>
          {character.pros ? (
            <div className="section-analysis">
              <Row className="pros-cons">
                <Col sm="12" md="6">
                  <div className="box pros">
                    <h5>Pros</h5>
                    <hr />
                    <div className="raw list">
                      {renderRichText(character.pros, options)}
                    </div>
                  </div>
                </Col>
                <Col sm="12" md="6">
                  <div className="box cons">
                    <h5>Cons</h5>
                    <hr />
                    <div className="raw list">
                      {renderRichText(character.cons, options)}
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          ) : (
            <div className="info-box">
              <p>
                <strong className={`${character.afflatus}`}>
                  {character.name}
                </strong>{' '}
                <strong>pros & cons aren't available yet.</strong> They will be
                added when the character is released.
              </p>
            </div>
          )}
          <div className={`content-header ${character.afflatus}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Ratings
          </div>
          {character.ratingsNew.standard_cn != 1 ? (
            <>
              {character.tierComment && (
                <p className="comment">{character.tierComment.tierComment}</p>
              )}
              <div className="detailed-ratings reverse">
                <RatingBox
                  game="reverse"
                  rating={character.ratingsNew.overall}
                  ratingName="Overall"
                />
                <RatingBox
                  game="reverse"
                  rating={character.ratingsNew.standard_global}
                  ratingName="Standard (GLB)"
                />
                <RatingBox
                  game="reverse"
                  rating={character.ratingsNew.standard_cn}
                  ratingName="Standard (CN)"
                />
                <RatingBox
                  game="reverse"
                  rating={character.ratingsNew.mane_global}
                  ratingName="Mane (GLB)"
                />
                <RatingBox
                  game="reverse"
                  rating={character.ratingsNew.mane_cn}
                  ratingName="Mane (CN)"
                />
              </div>
            </>
          ) : (
            <div className="info-box">
              <p>
                <strong className={`${character.afflatus}`}>
                  {character.name}
                </strong>{' '}
                <strong>ratings aren't available yet.</strong> They will be
                added when the character is released.
              </p>
            </div>
          )}
        </div>
        <div className={`tab-inside ${currentTab === 'Build' ? 'active' : ''}`}>
          <div className={`mobile-header ${character.afflatus}`}>
            <p>
              <FontAwesomeIcon icon={faDiagramProject} width="18" />
              Stats and Build
            </p>
          </div>
          <div className={`content-header ${character.afflatus}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Resonance
          </div>
          <p>
            For Resonance, we have created a dedicated website to browse them
            easier. You can find the website here:
          </p>
          <OutboundLink href="https://1999reso.carrd.co/" target="_blank">
            <Button variant="primary">Resonance Guide</Button>
          </OutboundLink>
          <div className={`content-header ${character.afflatus}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Psychubes
          </div>
          {character.psychubeSuggested ? (
            <>
              <div className={`employees-container psychube`}>
                {character.psychubeSuggested.map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <Reverse1999Psychube
                          slug={emp.slug}
                          mode="icon"
                          showLabel
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                    </span>
                  );
                })}
              </div>
              {character.psychubeComments && (
                <>
                  <h5>Comments</h5>
                  <div className={`skill-description rev-special`}>
                    {renderRichText(character.psychubeComments, options)}
                  </div>
                </>
              )}
            </>
          ) : (
            <div className="info-box">
              <p>
                <strong className={`${character.afflatus}`}>
                  {character.name}
                </strong>{' '}
                <strong>Psychubes aren't available yet</strong>. They will be
                added soon!
              </p>
            </div>
          )}
          <div className={`content-header ${character.afflatus}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Stats
          </div>
          {character.characterStats ? (
            <>
              <div className="char-stats">
                <div className="char-stats-header">
                  <div className="stat">STAT</div>
                  <div className="ins">
                    <p className="title">Base</p>
                    <div className="insight">
                      <p>Level 1</p>
                      <p>Level 30</p>
                    </div>
                  </div>
                  <div className="ins">
                    <p className="title">Insight 1</p>
                    <div className="insight">
                      <p>Level 1</p>
                      <p>Level 40</p>
                    </div>
                  </div>
                  <div className="ins">
                    <p className="title">Insight 2</p>
                    <div className="insight">
                      <p>Level 1</p>
                      <p>Level 50</p>
                    </div>
                  </div>
                  <div className="ins last">
                    <p className="title">Insight 3</p>
                    <div className="insight">
                      <p>Level 1</p>
                      <p>Level 60</p>
                    </div>
                  </div>
                </div>
                <div className="char-stats-data">
                  <div className="stat">
                    <StaticImage
                      src="../images/reverse/icons/atr_atk.png"
                      alt="ATK"
                    />
                    <span>ATK</span>
                  </div>
                  <div className="ins">
                    <p className="hidden">Base (Level 1 / Level 30)</p>
                    <div className="insight">
                      <p>{character.characterStats.atk.insight_0_min}</p>
                      <p>{character.characterStats.atk.insight_0_max}</p>
                    </div>
                  </div>
                  <div className="ins">
                    <p className="hidden">Insight 1 (Level 1 / Level 40)</p>
                    <div className="insight">
                      <p>{character.characterStats.atk.insight_1_min}</p>
                      <p>{character.characterStats.atk.insight_1_max}</p>
                    </div>
                  </div>
                  <div className="ins">
                    <p className="hidden">Insight 2 (Level 1 / Level 50)</p>
                    <div className="insight">
                      <p>{character.characterStats.atk.insight_2_min}</p>
                      <p>{character.characterStats.atk.insight_2_max}</p>
                    </div>
                  </div>
                  <div className="ins last">
                    <p className="hidden">Insight 3 (Level 1 / Level 60)</p>
                    <div className="insight">
                      <p>
                        {character.rarity === '5' ||
                        character.rarity === '6' ? (
                          <> {character.characterStats.atk.insight_3_min}</>
                        ) : (
                          '-'
                        )}
                      </p>
                      <p>
                        {character.rarity === '5' ||
                        character.rarity === '6' ? (
                          <> {character.characterStats.atk.insight_3_max}</>
                        ) : (
                          '-'
                        )}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="char-stats-data">
                  <div className="stat">
                    <StaticImage
                      src="../images/reverse/icons/atr_hp.png"
                      alt="ATK"
                    />
                    <span>HP</span>
                  </div>
                  <div className="ins">
                    <p className="hidden">Base (Level 1 / Level 30)</p>
                    <div className="insight">
                      <p>{character.characterStats.hp.insight_0_min}</p>
                      <p>{character.characterStats.hp.insight_0_max}</p>
                    </div>
                  </div>
                  <div className="ins">
                    <p className="hidden">Insight 1 (Level 1 / Level 40)</p>
                    <div className="insight">
                      <p>{character.characterStats.hp.insight_1_min}</p>
                      <p>{character.characterStats.hp.insight_1_max}</p>
                    </div>
                  </div>
                  <div className="ins">
                    <p className="hidden">Insight 2 (Level 1 / Level 50)</p>
                    <div className="insight">
                      <p>{character.characterStats.hp.insight_2_min}</p>
                      <p>{character.characterStats.hp.insight_2_max}</p>
                    </div>
                  </div>
                  <div className="ins last">
                    <p className="hidden">Insight 3 (Level 1 / Level 60)</p>
                    <div className="insight">
                      <p>
                        {character.rarity === '5' ||
                        character.rarity === '6' ? (
                          <> {character.characterStats.hp.insight_3_min}</>
                        ) : (
                          '-'
                        )}
                      </p>
                      <p>
                        {character.rarity === '5' ||
                        character.rarity === '6' ? (
                          <> {character.characterStats.hp.insight_3_max}</>
                        ) : (
                          '-'
                        )}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="char-stats-data">
                  <div className="stat">
                    <StaticImage
                      src="../images/reverse/icons/atr_reality.png"
                      alt="ATK"
                    />
                    <span>Reality DEF</span>
                  </div>
                  <div className="ins">
                    <p className="hidden">Base (Level 1 / Level 30)</p>
                    <div className="insight">
                      <p>
                        {character.characterStats.reality_def.insight_0_min}
                      </p>
                      <p>
                        {character.characterStats.reality_def.insight_0_max}
                      </p>
                    </div>
                  </div>
                  <div className="ins">
                    <p className="hidden">Insight 1 (Level 1 / Level 40)</p>
                    <div className="insight">
                      <p>
                        {character.characterStats.reality_def.insight_1_min}
                      </p>
                      <p>
                        {character.characterStats.reality_def.insight_1_max}
                      </p>
                    </div>
                  </div>
                  <div className="ins">
                    <p className="hidden">Insight 2 (Level 1 / Level 50)</p>
                    <div className="insight">
                      <p>
                        {character.characterStats.reality_def.insight_2_min}
                      </p>
                      <p>
                        {character.characterStats.reality_def.insight_2_max}
                      </p>
                    </div>
                  </div>
                  <div className="ins last">
                    <p className="hidden">Insight 3 (Level 1 / Level 60)</p>
                    <div className="insight">
                      <p>
                        {character.rarity === '5' ||
                        character.rarity === '6' ? (
                          <>
                            {character.characterStats.reality_def.insight_3_min}
                          </>
                        ) : (
                          '-'
                        )}
                      </p>
                      <p>
                        {character.rarity === '5' ||
                        character.rarity === '6' ? (
                          <>
                            {character.characterStats.reality_def.insight_3_max}
                          </>
                        ) : (
                          '-'
                        )}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="char-stats-data">
                  <div className="stat">
                    <StaticImage
                      src="../images/reverse/icons/atr_mental.png"
                      alt="ATK"
                    />
                    <span>Mental DEF</span>
                  </div>
                  <div className="ins">
                    <p className="hidden">Base (Level 1 / Level 30)</p>
                    <div className="insight">
                      <p>{character.characterStats.mental_def.insight_0_min}</p>
                      <p>{character.characterStats.mental_def.insight_0_max}</p>
                    </div>
                  </div>
                  <div className="ins">
                    <p className="hidden">Insight 1 (Level 1 / Level 40)</p>
                    <div className="insight">
                      <p>{character.characterStats.mental_def.insight_1_min}</p>
                      <p>{character.characterStats.mental_def.insight_1_max}</p>
                    </div>
                  </div>
                  <div className="ins">
                    <p className="hidden">Insight 2 (Level 1 / Level 50)</p>
                    <div className="insight">
                      <p>{character.characterStats.mental_def.insight_2_min}</p>
                      <p>{character.characterStats.mental_def.insight_2_max}</p>
                    </div>
                  </div>
                  <div className="ins last">
                    <p className="hidden">Insight 3 (Level 1 / Level 60)</p>
                    <div className="insight">
                      <p>
                        {character.rarity === '5' ||
                        character.rarity === '6' ? (
                          <>
                            {character.characterStats.mental_def.insight_3_min}
                          </>
                        ) : (
                          '-'
                        )}
                      </p>
                      <p>
                        {character.rarity === '5' ||
                        character.rarity === '6' ? (
                          <>
                            {character.characterStats.mental_def.insight_3_max}
                          </>
                        ) : (
                          '-'
                        )}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="char-stats-data">
                  <div className="stat">
                    <StaticImage
                      src="../images/reverse/icons/atr_crittech.png"
                      alt="ATK"
                    />
                    <span>Critical Technique</span>
                  </div>
                  <div className="ins">
                    <p className="hidden">Base (Level 1 / Level 30)</p>
                    <div className="insight">
                      <p>{character.characterStats.crit.insight_0_min}</p>
                      <p>{character.characterStats.crit.insight_0_max}</p>
                    </div>
                  </div>
                  <div className="ins">
                    <p className="hidden">Insight 1 (Level 1 / Level 40)</p>
                    <div className="insight">
                      <p>{character.characterStats.crit.insight_1_min}</p>
                      <p>{character.characterStats.crit.insight_1_max}</p>
                    </div>
                  </div>
                  <div className="ins">
                    <p className="hidden">Insight 2 (Level 1 / Level 50)</p>
                    <div className="insight">
                      <p>{character.characterStats.crit.insight_2_min}</p>
                      <p>{character.characterStats.crit.insight_2_max}</p>
                    </div>
                  </div>
                  <div className="ins last">
                    <p className="hidden">Insight 3 (Level 1 / Level 60)</p>
                    <div className="insight">
                      <p>
                        {character.rarity === '5' ||
                        character.rarity === '6' ? (
                          <> {character.characterStats.crit.insight_3_min}</>
                        ) : (
                          '-'
                        )}
                      </p>
                      <p>
                        {character.rarity === '5' ||
                        character.rarity === '6' ? (
                          <> {character.characterStats.crit.insight_3_max}</>
                        ) : (
                          '-'
                        )}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="char-stats-data">
                  <div className="stat">
                    <StaticImage
                      src="../images/reverse/icons/atr_critrate.png"
                      alt="ATK"
                    />
                    <span>Critical Rate</span>
                  </div>
                  <div className="ins">
                    <p className="hidden">Base (Level 1 / Level 30)</p>
                    <div className="insight">
                      <p>{character.characterStats.crit_rate.insight_0_min}%</p>
                      <p>{character.characterStats.crit_rate.insight_0_max}%</p>
                    </div>
                  </div>
                  <div className="ins">
                    <p className="hidden">Insight 1 (Level 1 / Level 40)</p>
                    <div className="insight">
                      <p>{character.characterStats.crit_rate.insight_1_min}%</p>
                      <p>{character.characterStats.crit_rate.insight_1_max}%</p>
                    </div>
                  </div>
                  <div className="ins">
                    <p className="hidden">Insight 2 (Level 1 / Level 50)</p>
                    <div className="insight">
                      <p>{character.characterStats.crit_rate.insight_2_min}%</p>
                      <p>{character.characterStats.crit_rate.insight_2_max}%</p>
                    </div>
                  </div>
                  <div className="ins last">
                    <p className="hidden">Insight 3 (Level 1 / Level 60)</p>
                    <div className="insight">
                      <p>
                        {character.rarity === '5' ||
                        character.rarity === '6' ? (
                          <>
                            {character.characterStats.crit_rate.insight_3_min}%
                          </>
                        ) : (
                          '-'
                        )}
                      </p>
                      <p>
                        {character.rarity === '5' ||
                        character.rarity === '6' ? (
                          <>
                            {character.characterStats.crit_rate.insight_3_max}%
                          </>
                        ) : (
                          '-'
                        )}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="char-stats-data">
                  <div className="stat">
                    <StaticImage
                      src="../images/reverse/icons/atr_critdmg.png"
                      alt="ATK"
                    />
                    <span>Critical DMG</span>
                  </div>
                  <div className="ins">
                    <p className="hidden">Base (Level 1 / Level 30)</p>
                    <div className="insight">
                      <p>{character.characterStats.crit_dmg.insight_0_min}%</p>
                      <p>{character.characterStats.crit_dmg.insight_0_max}%</p>
                    </div>
                  </div>
                  <div className="ins">
                    <p className="hidden">Insight 1 (Level 1 / Level 40)</p>
                    <div className="insight">
                      <p>{character.characterStats.crit_dmg.insight_1_min}%</p>
                      <p>{character.characterStats.crit_dmg.insight_1_max}%</p>
                    </div>
                  </div>
                  <div className="ins">
                    <p className="hidden">Insight 2 (Level 1 / Level 50)</p>
                    <div className="insight">
                      <p>{character.characterStats.crit_dmg.insight_2_min}%</p>
                      <p>{character.characterStats.crit_dmg.insight_2_max}%</p>
                    </div>
                  </div>
                  <div className="ins last">
                    <p className="hidden">Insight 3 (Level 1 / Level 60)</p>
                    <div className="insight">
                      <p>
                        {character.rarity === '5' ||
                        character.rarity === '6' ? (
                          <>
                            {' '}
                            {character.characterStats.crit_dmg.insight_3_min}%
                          </>
                        ) : (
                          '-'
                        )}
                      </p>
                      <p>
                        {character.rarity === '5' ||
                        character.rarity === '6' ? (
                          <>
                            {' '}
                            {character.characterStats.crit_dmg.insight_3_max}%
                          </>
                        ) : (
                          '-'
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="info-box">
              <p>
                <strong className={`${character.afflatus}`}>
                  {character.name}
                </strong>{' '}
                <strong>stats aren't available yet</strong>. They will be added
                soon!
              </p>
            </div>
          )}
        </div>
        <div className={`tab-inside ${currentTab === 'Teams' ? 'active' : ''}`}>
          <div className={`mobile-header ${character.afflatus}`}>
            <p>
              <FontAwesomeIcon icon={faPeopleGroup} width="18" />
              Synergy & Teams
            </p>
          </div>
          <div className={`content-header  ${character.afflatus}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Synergy & Teams
          </div>
          <div className="info-box">
            <p>
              <strong className={`${character.afflatus}`}>
                {character.name}
              </strong>{' '}
              <strong>Synergy & Teams information aren't available yet</strong>.
              They will be added soon!
            </p>
          </div>
        </div>
        <div
          className={`tab-inside ${
            currentTab === 'Performance' ? 'active' : ''
          }`}
        >
          <div className={`mobile-header ${character.afflatus}`}>
            <p>
              <FontAwesomeIcon icon={faReceipt} width="18" />
              Lore
            </p>
          </div>
          <div className={`content-header ${character.afflatus}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Lore
          </div>
          <div className="info-box">
            <p>
              <strong className={`${character.afflatus}`}>
                {character.name}
              </strong>{' '}
              <strong>Lore information aren't available yet</strong>. They will
              be added soon!
            </p>
          </div>
        </div>
        <div className="fuse-ad-placeholder" data-fuse="22844297232"></div>
      </>
    </DashboardLayout>
  );
};

export default ReverseCharacterDetails;

export const Head: React.FC<IProps> = ({ data }) => {
  const character = data.currentUnit.nodes[0];

  return (
    <Seo
      title={character.name + ' | Reverse 1999 | Prydwen Institute'}
      description={
        'Discover the information and guides for ' +
        character.name +
        ' in Reverse 1999.'
      }
      image={character.imageFull}
    />
  );
};

export const pageQuery = graphql`
  query ($contentfulId: String) {
    currentUnit: allContentfulReverseCharacter(
      filter: { id: { eq: $contentfulId } }
    ) {
      nodes {
        ...ReverseCharacterFieldsFragment
      }
    }
  }
`;
