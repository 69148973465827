import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

interface IProps {
  name: string;
  value: number;
}

export const ReverseItem: React.FC<IProps> = ({ name, value }) => {
  return (
    <div className="reverse-item-inline">
      {name === 'Sharpodonty' && (
        <StaticImage
          src="../../../../images/reverse/materials/sharpodonty.webp"
          alt={name}
        />
      )}
      {name === 'Dust' && (
        <StaticImage
          src="../../../../images/reverse/materials/dust.webp"
          alt={name}
        />
      )}
      {name === 'Winged Key' && (
        <StaticImage
          src="../../../../images/reverse/materials/winged_key.webp"
          alt={name}
        />
      )}
      {name === 'Caduceus' && (
        <StaticImage
          src="../../../../images/reverse/materials/caduceus.webp"
          alt={name}
        />
      )}
      {name === 'Magnesia Crystal' && (
        <StaticImage
          src="../../../../images/reverse/materials/magnesia.webp"
          alt={name}
        />
      )}
      {name === 'Shattered Bones' && (
        <StaticImage
          src="../../../../images/reverse/materials/shattered_bones.webp"
          alt={name}
        />
      )}
      {name === 'Silver Ore' && (
        <StaticImage
          src="../../../../images/reverse/materials/silver_ore.webp"
          alt={name}
        />
      )}
      {name === 'Spell of Banishing' && (
        <StaticImage
          src="../../../../images/reverse/materials/spell_of_banishing.webp"
          alt={name}
        />
      )}
      {name === 'Trembling Tooth' && (
        <StaticImage
          src="../../../../images/reverse/materials/trembling_tooth.webp"
          alt={name}
        />
      )}
      {name === 'Esoteric Bones' && (
        <StaticImage
          src="../../../../images/reverse/materials/esoteric_bones.webp"
          alt={name}
        />
      )}
      {name === 'Sonorous Knell' && (
        <StaticImage
          src="../../../../images/reverse/materials/sonorous_knell.webp"
          alt={name}
        />
      )}
      {name === 'Sinuous Howl' && (
        <StaticImage
          src="../../../../images/reverse/materials/sinuous_howl.webp"
          alt={name}
        />
      )}
      {name === 'Moment of Dissonance' && (
        <StaticImage
          src="../../../../images/reverse/materials/moment_of_dissonance.webp"
          alt={name}
        />
      )}
      {name === 'Interlaced Shudder' && (
        <StaticImage
          src="../../../../images/reverse/materials/interlaced_shudder.webp"
          alt={name}
        />
      )}
      {name === 'Hypocritical Murmur' && (
        <StaticImage
          src="../../../../images/reverse/materials/hypocritical_murmur.webp"
          alt={name}
        />
      )}
      {name === 'Hoarse Echo' && (
        <StaticImage
          src="../../../../images/reverse/materials/hoarse_echo.webp"
          alt={name}
        />
      )}
      {name === 'Brief Cacophony' && (
        <StaticImage
          src="../../../../images/reverse/materials/brief_cacophony.webp"
          alt={name}
        />
      )}
      {name === 'Tome of Starlit Ascent' && (
        <StaticImage
          src="../../../../images/reverse/materials/tome_of_starlit_ascent.webp"
          alt={name}
        />
      )}
      {name === 'Tome of Plantal Vimen' && (
        <StaticImage
          src="../../../../images/reverse/materials/tome_of_plantal_vimen.webp"
          alt={name}
        />
      )}
      {name === 'Tome of Mineral Wealth' && (
        <StaticImage
          src="../../../../images/reverse/materials/tome_of_mineral_wealth.webp"
          alt={name}
        />
      )}
      {name === 'Tome of Beastly Thirst' && (
        <StaticImage
          src="../../../../images/reverse/materials/tome_of_beastly_thirst.webp"
          alt={name}
        />
      )}
      {name === 'Scroll of Starlit Ascent' && (
        <StaticImage
          src="../../../../images/reverse/materials/scroll_of_starlit_ascent.webp"
          alt={name}
        />
      )}
      {name === 'Scroll of Plantal Vimen' && (
        <StaticImage
          src="../../../../images/reverse/materials/scroll_of_plantal_vimen.webp"
          alt={name}
        />
      )}
      {name === 'Scroll of Mineral Wealth' && (
        <StaticImage
          src="../../../../images/reverse/materials/scroll_of_mineral_wealth.webp"
          alt={name}
        />
      )}
      {name === 'Scroll of Beastly Thirst' && (
        <StaticImage
          src="../../../../images/reverse/materials/scroll_of_beastly_thirst.webp"
          alt={name}
        />
      )}
      {name === 'Page of Starlit Ascent' && (
        <StaticImage
          src="../../../../images/reverse/materials/page_of_starlit_ascent.webp"
          alt={name}
        />
      )}
      {name === 'Page of Plantal Vimen' && (
        <StaticImage
          src="../../../../images/reverse/materials/page_of_planta_vimen.webp"
          alt={name}
        />
      )}
      {name === 'Page of Mineral Wealth' && (
        <StaticImage
          src="../../../../images/reverse/materials/page_of_mineral_wealth.webp"
          alt={name}
        />
      )}
      {name === 'Page of Beastly Thirst' && (
        <StaticImage
          src="../../../../images/reverse/materials/page_of_beastly_thirst.webp"
          alt={name}
        />
      )}
      {name === 'Wyrmling Skeleton' && (
        <StaticImage
          src="../../../../images/reverse/materials/wyrmling_skeleton.webp"
          alt={name}
        />
      )}
      {name === 'Silver Bullet' && (
        <StaticImage
          src="../../../../images/reverse/materials/silver_bullet.webp"
          alt={name}
        />
      )}
      {name === 'Platinum Ouija' && (
        <StaticImage
          src="../../../../images/reverse/materials/platinum_ouija.webp"
          alt={name}
        />
      )}
      {name === 'Murmur of Insanity' && (
        <StaticImage
          src="../../../../images/reverse/materials/murmur_of_insanity.webp"
          alt={name}
        />
      )}
      {name === 'Mistilteinn' && (
        <StaticImage
          src="../../../../images/reverse/materials/mistilteinn.webp"
          alt={name}
        />
      )}
      {name === 'Incorrupt Monkeypaw' && (
        <StaticImage
          src="../../../../images/reverse/materials/incorrupt_monkeypaw.webp"
          alt={name}
        />
      )}
      {name === 'Golden Fleece' && (
        <StaticImage
          src="../../../../images/reverse/materials/golden_fleece.webp"
          alt={name}
        />
      )}
      {name === 'Fruit of Good and Evil' && (
        <StaticImage
          src="../../../../images/reverse/materials/fruit.webp"
          alt={name}
        />
      )}
      {name === 'Bogeyman' && (
        <StaticImage
          src="../../../../images/reverse/materials/bogeyman.webp"
          alt={name}
        />
      )}
      {name === 'Salted Mandrake' && (
        <StaticImage
          src="../../../../images/reverse/materials/salted.webp"
          alt={name}
        />
      )}
      {name === 'Prophetic Bird' && (
        <StaticImage
          src="../../../../images/reverse/materials/prophetic_bird.webp"
          alt={name}
        />
      )}
      {name === 'Holy Silver' && (
        <StaticImage
          src="../../../../images/reverse/materials/holy_silver.webp"
          alt={name}
        />
      )}
      {name === 'Clawed Pendulum' && (
        <StaticImage
          src="../../../../images/reverse/materials/clawed_pendulum.webp"
          alt={name}
        />
      )}
      {name === 'Biting Box' && (
        <StaticImage
          src="../../../../images/reverse/materials/biting_box.webp"
          alt={name}
        />
      )}
      {name === 'Bifurcated Skeleton' && (
        <StaticImage
          src="../../../../images/reverse/materials/bifurcated_skeleton.webp"
          alt={name}
        />
      )}
      {name === 'Spell of Fortune' && (
        <StaticImage
          src="../../../../images/reverse/materials/spell_of_fortune.webp"
          alt={name}
        />
      )}
      {name === 'Solidus' && (
        <StaticImage
          src="../../../../images/reverse/materials/solidus.webp"
          alt={name}
        />
      )}
      {name === 'Rough Silver Ingot' && (
        <StaticImage
          src="../../../../images/reverse/materials/rough_silver_ingot.webp"
          alt={name}
        />
      )}
      {name === 'Milled Magnesia' && (
        <StaticImage
          src="../../../../images/reverse/materials/milled_magnesia.webp"
          alt={name}
        />
      )}
      {name === 'Liquified Terror' && (
        <StaticImage
          src="../../../../images/reverse/materials/liquified_terror.webp"
          alt={name}
        />
      )}
      <span className="name">{name}</span>
      <span className="value">x{value}</span>
    </div>
  );
};
